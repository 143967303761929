<template>
  <v-card>
      <v-data-table
        :headers="columns"
        :items="users"
        :options.sync="options"
        :server-items-length="usersTotal"
        :loading="loading"
        :header-props="headerprops"
        class="table-section text-no-wrap"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          {{ item.id }}
        </template>

        <!-- Role -->
        <template #[`item.roles`]="{ item }">
          <v-chip v-for="role in item.roles" key="role.id" class="v-chip-light-bg primary--text font-weight-semibold">{{ t(getNameTranslationByName(role)) }}</v-chip>
        </template>

        <!-- Active -->
        <template #[`item.active`]="{ item }">
          <v-chip
            :color="item.active ? 'success' : 'error'"
            dark
            small
          >
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <template #[`item.joined_at`]="{ item }">
          {{ formatDatetime(item.joined_at) }}
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                >
                  <v-list-item-title>
                    <edit-dialog :user="item" @updated="onUserUpdated" />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
  </v-card>
</template>

<script>
import { useOldDataTable } from '@/composables'
import { t } from '@/plugins/i18n'
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh, mdiSync } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useUserRole from '../../composables/userRole'
import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
    usersTotal: {
      type: Number,
      default: 0,
    },
  },
  components: {
    EditDialog,
  },
  setup(props, { emit }) {
    const { getNameTranslationByName } = useUserRole()
    const { isPageManualChange, defaultOptions, headerprops } = useOldDataTable()

    // add custom options
    const options = ref({
      ...defaultOptions,
    })

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Role', value: 'roles', sortable: false },
      { text: 'Status', value: 'active', sortable: false },
      { text: 'Joined At', value: 'joined_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const formatDatetime = datetime => {
      return dayjs(datetime).utc().local().format('YYYY-MM-DD')
    }

    const onUserUpdated = () => {
      emit('user-updated')
    }

    const resetPage = (page = 1) => {
      isPageManualChange.value = true

      options.value.page = page

      setTimeout(() => {
        isPageManualChange.value = false
      }, 500)
    }

    watch(
      () => options.value,
      newValue => {
        if (!isPageManualChange.value) {
          emit('options-updated', newValue)
        }
      },
    )

    return {
      t,
      dayjs,

      // Table config
      columns,
      options,
      headerprops,

      getNameTranslationByName,
      formatDatetime,

      onUserUpdated,

      resetPage,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
        mdiSync,
      },
    }
  },
}
</script>