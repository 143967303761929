<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5">
        <slot name="title">
          Create Admin User
        </slot>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="form.name"
              label="Name"
              :rules="[validators.required]"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.email"
              label="Email"
              :rules="[validators.required]"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.password"
              label="Password"
              autocomplete="off"
              :type="isPasswordVisible ? 'text' : 'password'"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :append-outer-icon="icons.mdiRefresh"
              :rules="isEditing ? [] : [validators.required, validators.passwordValidator]"
              @click:append="isPasswordVisible = !isPasswordVisible"
              @click:append-outer="newPassword()"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.password_confirmation"
              label="Confirm Password"
              autocomplete="off"
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              placeholder="············"
              :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="isEditing ? [] : [validators.required, validators.passwordValidator]"
              @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
            />
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="isDatePickerVisible"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.joined_at"
                  readonly
                  v-bind="attrs"
                  label="Join Date"
                  persistent-hint
                  :rules="[validators.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.joined_at"
                color="primary"
                @input="isDatePickerVisible = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="form.role_id"
              :items="roleOptions"
              item-text="title"
              item-value="value"
              label="Role"
              placeholder="Select a role"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="form.active"
              label="Active"
              placeholder="Select a role"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          @click="onClose"
        >
          Close
        </v-btn>
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { useGeneratePassword } from '@/composables'
import { mdiEyeOffOutline, mdiEyeOutline, mdiPlus, mdiRefresh } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'

import { emailValidator, passwordValidator, required } from '@core/utils/validation'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useUserRole from '../../composables/userRole'

dayjs.extend(utc)

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isEditing = ref(false)
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const isDatePickerVisible = ref(false)

    const defaultFormValue = {
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      joined_at: null,
      role_id: null,
      active: true,
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const { roles, getRoleIdByName } = useUserRole()

    const roleOptions = computed(() =>
      roles.value.map(role => ({
        title: role.name_translation.en,
        value: role.id,
      })),
    )

    const newPassword = () => {
      form.value.password = useGeneratePassword()
      form.value.password_confirmation = form.value.password
    }

    const parseEditUser = user => ({
      name: user.name,
      email: user.email,
      password: null,
      password_confirmation: null,
      joined_at: dayjs(user.joined_at).format('YYYY-MM-DD'),
      role_id: getRoleIdByName(user.roles[0]),
      active: user.active,
    })

    // If editing, set the user data
    onMounted(() => {
      if (props.user) {
        isEditing.value = true

        form.value = { ...parseEditUser(props.user) }
      } else {
        newPassword()
      }
    })

    const onClose = () => {
      resetForm()

      emit('close')
    }

    const onSubmit = () => {
      // validate form
      if (formRef.value.validate()) {
        loading.value = true

        emit('submit', form.value)
      }
    }

    const resetForm = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)

        newPassword()
      }

      loading.value = false
    }

    return {
      loading,

      isEditing,
      isPasswordVisible,
      isConfirmPasswordVisible,
      isDatePickerVisible,

      form,
      formRef,
      roleOptions,

      // Rules
      validators: {
        required,
        emailValidator,
        passwordValidator,
      },

      newPassword,

      onClose,
      onSubmit,
      resetForm,

      icons: {
        mdiPlus,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>
