<template>
  <v-select
    v-model="active"
    :placeholder="$t('common.list.selectStatus')"
    :items="statusOptions"
    item-value="value"
    item-text="title"
    single-line
    dense
    outlined
    clearable
    hide-details
  />
</template>

<script>
import { computed, ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'status',
    event: 'changed',
  },
  props: {
    status: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const active = computed({
      get: () => props.status,
      set: value => {
        emit('changed', value)
      },
    })

    const statusOptions = ref([
      {
        title: 'Inactive',
        value: 0,
      },
      {
        title: 'Active',
        value: 1,
      },
    ])

    return {
      active,
      statusOptions,
    }
  },
}
</script>
