var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.users,
      "options": _vm.options,
      "server-items-length": _vm.usersTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.roles",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.roles, function (role) {
          return _c('v-chip', {
            key: "role.id",
            staticClass: "v-chip-light-bg primary--text font-weight-semibold"
          }, [_vm._v(_vm._s(_vm.t(_vm.getNameTranslationByName(role))))]);
        });
      }
    }, {
      key: "item.active",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          attrs: {
            "color": item.active ? 'success' : 'error',
            "dark": "",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.active ? 'Active' : 'Inactive') + " ")])];
      }
    }, {
      key: "item.joined_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.formatDatetime(item.joined_at)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          }
        }, [_c('v-list-item-title', [_c('edit-dialog', {
          attrs: {
            "user": item
          },
          on: {
            "updated": _vm.onUserUpdated
          }
        })], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }