<template>
  <div>
    <user-filter
      v-model="filters"
      class="mb-3"
      @updated="onFilterUpdated"
    />

    <v-card>
      <!-- Buttons -->
      <v-card-text class="d-flex justify-space-between">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="fetchData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>

        <create-dialog @created="fetchData" />
      </v-card-text>

      <!-- Table -->
      <user-table
        ref="tableRef"
        :loading="loading"
        :users="users"
        :users-total="usersTotal"
        @user-updated="fetchData"
        @options-updated="onOptionsUpdated"
      />
    </v-card>
  </div>
</template>

<script>
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh, mdiSync } from '@mdi/js'

import { useOldDataFetch, useOldTableList } from '@/composables'
import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'
import CreateDialog from '../components/user/CreateDialog.vue'
import UserFilter from '../components/user/UserFilter.vue'
import UserTable from '../components/user/UserTable.vue'
import { userService } from '../services'

export default {
  components: {
    UserFilter,
    UserTable,
    CreateDialog,
  },
  setup() {
    const { parseParam } = useOldDataFetch()
    const { loading, tableRef, mergeOptionToParams } = useOldTableList()

    const users = ref([])
    const usersTotal = ref(0)

    const filters = ref({
      search: null,
      active: null,
      role_id: null,
    })

    const params = ref({})

    const fetchData = async () => {
      loading.value = true

      try {
        const { records, pagination } = await userService.index(parseParam(params.value))

        users.value = records
        usersTotal.value = pagination.total
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const onFilterUpdated = value => {
      params.value = {
        ...params.value,
        ...value,
      }

      // Reset page
      params.value.page = 1
      tableRef.value.resetPage(1)

      fetchData()
    }

    const onOptionsUpdated = options => {
      params.value = mergeOptionToParams(options, params.value)

      fetchData()
    }

    return {
      // Dependecies
      t,

      // Refs
      loading,
      tableRef,
      filters,
      users,
      usersTotal,

      // functions
      fetchData,
      onFilterUpdated,
      onOptionsUpdated,

      // Icons
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
        mdiSync,
      },
    }
  },
}
</script>
