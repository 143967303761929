// create composable function to fetch roles
import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useUserRole() {
  const fetchRoles = () =>
    store.dispatch('admin/fetchRoles', {
      paginate: 0,
    })

  const roles = computed(() => store.getters['admin/getRoles'])

  if (!store.getters['admin/getRoles'].length) {
    fetchRoles()
  }

  const getRoleIdByName = roleName => {
    const role = store.getters['admin/getRoles'].find(role => role.name === roleName)

    return role ? role.id : ''
  }

  const getRoleNameById = roleId => {
    const role = store.getters['admin/getRoles'].find(role => role.id === roleId)

    return role ? role.name : ''
  }

  const getNameTranslationByName = roleName => {
    const role = store.getters['admin/getRoles'].find(role => role.name === roleName)

    return role ? role.name_translation : ''
  }

  return {
    roles,
    fetchRoles,
    getRoleIdByName,
    getRoleNameById,
    getNameTranslationByName,
  }
}
